import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Typography from "@mui/material/Typography";

import { os22, os21Evento, leisure21, os20 } from "../Images";
import { styled } from "@mui/system";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/base/ButtonUnstyled";

const black = {
  500: "#010203",
  600: "#121212",
  700: "#232323",
};

const CustomButton = styled(ButtonUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${black[500]};
  padding: 12px 24px;
  border-radius: 12px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${black[600]};
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${black[700]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const useStyles = makeStyles((theme) => ({
  parent: {
    // Parent div styles

    "&:hover $imageDescription1": {
      display: "flex",
    },
    "&:hover $hoverChild": {
      display: "block",
    },
  },
  imageDescription1: {
    display: "none",
  },
  hoverChild: {
    // Styles for the child div when parent is being hovered
    display: "none",
    fontWeight: 600,
    margin: "5%",
    position: "absolute",
    width: "100%",
    height: "20%",
  },
}));

const theme = createTheme();

export default function Collection() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const onOpenModal = (item) => {
    window.open(item.img, "_self");
  };

  const contactButton = (e, title, look) => {
    e.stopPropagation();
    navigate("/Contact", {
      state: { title: title, look: look, collection: state?.type },
    });
  };

  theme.typography.h6 = {
    fontSize: "0.7rem",
    "@media (min-width:600px)": {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
  };

  return (
    <React.Fragment>
      <ImageList sx={{ width: "100%", height: "100%" }} cols={state.row}>
        {itemData[state.type].map((item, index) => (
          // <ThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <ImageListItem
              className={`list-item ${classes.parent}`}
              key={item.img}
              // onMouseEnter={showTitleAndDescription}
              onClick={() => onOpenModal(item)}
            >
              <img
                src={`${
                  state.type == "os22" || state.type == "evento21"
                    ? item.webpimg
                    : item.img
                }?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${
                  state.type == "os22" || state.type == "evento21"
                    ? item.webpimg
                    : item.img
                }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <div className={`${classes.child} ${classes.hoverChild}`}>
                <p>{item.look}</p>
              </div>
              <div
                className={`imageDescription KnowMore ${classes.imageDescription1}`}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    sx={{ width: "50%" }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    {item.title}
                  </Typography>

                  <div
                    style={{ margin: "auto", height: "50%", width: "50%" }}
                    className="KnowMore"
                  >
                    <Stack spacing={2} direction="row">
                      <CustomButton
                        onClick={(e) => contactButton(e, item.title, item.look)}
                      >
                        <Typography
                          sx={{ color: "#fff" }}
                          gutterBottom
                          variant="h6"
                          component="div"
                        >
                          Get Details
                        </Typography>
                      </CustomButton>
                    </Stack>
                  </div>
                </ThemeProvider>
              </div>
            </ImageListItem>
          </ThemeProvider>
        ))}
      </ImageList>
    </React.Fragment>
  );
}

const itemData = {
  os22: [
    {
      img: os22[1],
      webpimg: os22.w1,
      alt: "Double breasted honeycomb jacket",
      title: "Double breasted honeycomb jacket",
      look: "Look 1",
    },
    {
      img: os22[2],
      webpimg: os22.w2,
      alt: "Double breasted honeycomb jacket",
      title: "Double breasted honeycomb jacket",
      look: "Look 1",
    },
    {
      img: os22[3],
      webpimg: os22.w3,
      alt: "Double breasted honeycomb jacket",
      title: "Double-breasted BLAZER",
      look: "Look 1",
    },
    // {
    //   img: os22[4],
    //   webpimg: os22.w4,
    //   alt: "Double breasted honeycomb jacket",
    //   title: "Double-breasted BLAZER",
    //   look: "Look 1",
    // },

    {
      img: os22[5],
      webpimg: os22.w5,
      alt: "Grey double-breasted suit",
      title: "Grey double-breasted suit",
      look: "Look 2",
    },
    {
      img: os22[6],
      webpimg: os22.w6,
      alt: "Grey double-breasted suit",
      title: "Grey double-breasted suit",
      look: "Look 2",
    },
    {
      img: os22[7],
      webpimg: os22.w7,
      alt: "Grey double-breasted suit",
      title: "Grey double-breasted suit",
      look: "Look 2",
    },
    // {
    //   img: os22[8],
    //   webpimg: os22.w8,
    //   alt: "Grey double-breasted suit",
    //   title: "Grey double-breasted suit",
    //   look: "Look 2",
    // },

    {
      img: os22[9],
      webpimg: os22.w9,
      alt: "Orange corduroy suits",
      title: "Orange corduroy suits",
      look: "Look 3",
    },
    {
      img: os22[10],
      webpimg: os22.w10,
      alt: "Orange corduroy suits",
      title: "Orange corduroy suits",
      look: "Look 3",
    },
    {
      img: os22[11],
      webpimg: os22.w11,
      alt: "Corduroy suit",
      title: "Orange corduroy suits",
      look: "Look 3",
    },
    // {
    //   img: os22[12],
    //   webpimg: os22.w12,
    //   alt: "Corduroy suit",
    //   title: "Orange corduroy suits",
    //   look: "Look 3",
    // },

    {
      img: os22[13],
      webpimg: os22.w13,
      alt: "BABY BLUE DENIM SHIRT",
      title: "BABY BLUE DENIM SHIRT",
      look: "Look 4",
    },
    {
      img: os22[14],
      webpimg: os22.w14,
      alt: "BABY BLUE DENIM SHIRT",
      title: "BABY BLUE DENIM SHIRT",
      look: "Look 4",
    },
    {
      img: os22[15],
      webpimg: os22.w15,
      alt: "DENIM SHIRT",
      title: "BABY BLUE DENIM SHIRT",
      look: "Look 4",
    },
    // {
    //   img: os22[17],
    //   webpimg: os22.w17,
    //   alt: "DENIM SHIRT",
    //   title: "BABY BLUE DENIM SHIRT",
    //   look: "Look 4",
    // },

    {
      img: os22[18],
      webpimg: os22.w18,
      alt: "Orange corduroy suits",
      title: "Orange corduroy suits",
      look: "Look 5",
    },
    {
      img: os22[19],
      webpimg: os22.w19,
      alt: "Orange corduroy suits",
      title: "Orange corduroy suits",
      look: "Look 5",
    },
    {
      img: os22[20],
      webpimg: os22.w20,
      alt: "corduroy suits",
      title: "Orange corduroy suits",
      look: "Look 5",
    },
    // {
    //   img: os22[21],
    //   webpimg: os22.w21,
    //   alt: "corduroy suits",
    //   title: "Orange corduroy suits",
    //   look: "Look 5",
    // },

    {
      img: os22[22],
      webpimg: os22.w22,
      alt: "Velvet Bandhgala Jacket",
      title: "Velvet Bandhgala Jacket",
      look: "Look 6",
    },
    {
      img: os22[23],
      webpimg: os22.w23,
      alt: "Velvet Bandhgala Jacket",
      title: "Velvet Bandhgala Jacket",
      look: "Look 6",
    },
    {
      img: os22[24],
      webpimg: os22.w24,
      alt: "Velvet Bandhgala suit",

      title: "Velvet Bandhgala Jacket",
      look: "Look 6",
    },
    // {
    //   img: os22[25],
    //   webpimg: os22.w25,
    //   alt: "Velvet Bandhgala suit",
    //   title: "Velvet Bandhgala Jacket",
    //   look: "Look 6",
    // },
    {
      img: os22[26],
      webpimg: os22.w26,
      title: "Liquid velvet blazer",
      look: "Look 7",
    },
    {
      img: os22[27],
      webpimg: os22.w27,
      alt: "Velvet Bandhgala suit",
      title: "Liquid velvet blazer",
      look: "Look 7",
    },
    {
      img: os22[28],
      webpimg: os22.w28,
      alt: "Velvet evening jacket",
      title: "Liquid velvet blazer",
      look: "Look 7",
    },
    // {
    //   img: os22[29],
    //   webpimg: os22.w29,
    //   alt: "Velvet evening jacket",
    //   title: "Liquid velvet blazer",
    //   look: "Look 7",
    // },
    {
      img: os22[30],
      webpimg: os22.w30,
      alt: "Velvet evening jacket",
      title: "Velvet Bandhgala Jacket",
      look: "Look 8",
    },
    {
      img: os22[31],
      webpimg: os22.w31,
      alt: "Velvet evening jacket",
      title: "Velvet Bandhgala Jacket",
      look: "Look 8",
    },
    {
      img: os22[32],
      webpimg: os22.w32,
      alt: "Velvet Bandhgala suit",
      title: "Velvet Bandhgala Jacket",
      look: "Look 8",
    },

    // {
    //   img: os22[33],
    //   webpimg: os22.w33,
    //   alt: "Velvet Bandhgala suit",
    //   title: "Velvet Bandhgala Jacket",
    //   look: "Look 8",
    // },
    {
      img: os22[34],
      webpimg: os22.w34,
      alt: "Metallic suit",
      title: "Metallic suit jacket",
      look: "Look 9",
    },
    {
      img: os22[35],
      webpimg: os22.w35,
      alt: "Metallic suit",
      title: "Metallic suit jacket",
      look: "Look 9",
    },
    {
      img: os22[36],
      webpimg: os22.w36,
      alt: "Velvet Bandhgala suit",
      title: "Metallic suit jacket",
      look: "Look 9",
    },
    // {
    //   img: os22[37],
    //   webpimg: os22.w37,
    //   alt: "Velvet Bandhgala suit",
    //   title: "Metallic suit jacket",
    //   look: "Look 9",
    // },
    {
      img: os22[38],
      webpimg: os22.w38,
      alt: "007 tuxedo suit",
      title: "007 tuxedo suit",
      look: "Look 10",
    },
    {
      img: os22[39],
      webpimg: os22.w39,
      alt: "007 tuxedo suit",
      title: "007 tuxedo suit",
      look: "Look 10",
    },
    // {
    //   img: os22[40],
    //   webpimg: os22.w40,
    //   alt: "Classic tuxedo",
    //   title: "007 tuxedo suit",
    //   look: "Look 10",
    // },
    {
      img: os22[41],
      webpimg: os22.w41,
      alt: "Classic tuxedo",
      title: "007 tuxedo suit",
      look: "Look 10",
    },

    {
      img: os22[42],
      webpimg: os22.w42,
      alt: "Tuxedo shirt",
      title: "Tuxedo shirt",
      look: "Look 11",
    },
    {
      img: os22[43],
      webpimg: os22.w43,
      alt: "Tuxedo shirt",
      title: "Tuxedo shirt",
      look: "Look 11",
    },
    // {
    //   img: os22[44],
    //   webpimg: os22.w44,
    //   alt: "Wingtip tuxedo shirt",
    //   title: "Tuxedo shirt",
    //   look: "Look 11",
    // },
    {
      img: os22[45],
      webpimg: os22.w45,
      alt: "Wingtip tuxedo shirt",
      title: "Tuxedo shirt",
      look: "Look 11",
    },

    {
      img: os22[46],
      webpimg: os22.w46,
      alt: "Classic tuxedo",
      title: "007 tuxedo suit",
      look: "Look 12",
    },
    {
      img: os22[47],
      webpimg: os22.w47,
      alt: "Classic tuxedo",
      title: "007 tuxedo suit",
      look: "Look 12",
    },
    // {
    //   img: os22[48],
    //   webpimg: os22.w48,
    //   alt: "007 tuxedo suit",
    //   title: "007 tuxedo suit",
    //   look: "Look 12",
    // },
    {
      img: os22[49],
      webpimg: os22.w49,
      alt: "007 tuxedo suit",
      title: "007 tuxedo suit",
      look: "Look 12",
    },
    {
      img: os22[50],
      webpimg: os22.w50,
      alt: "Turquoise drawstring trouser",
      title: "Drawstring trousers",
      look: "Look 13",
    },
    {
      img: os22[51],
      webpimg: os22.w51,
      alt: "Turquoise drawstring trouser",
      title: "Drawstring trousers",
      look: "Look 13",
    },
    // {
    //   img: os22[52],
    //   webpimg: os22.w52,
    //   alt: "Tailored drawstring trousers",
    //   title: "Drawstring trousers",
    //   look: "Look 13",
    // },
    {
      img: os22[53],
      webpimg: os22.w53,
      alt: "Tailored drawstring trousers",
      title: "Drawstring trousers",
      look: "Look 13",
    },
    {
      img: os22[54],
      webpimg: os22.w54,
      alt: "Tailored drawstring trousers",
      title: "Sand Slub drawstring trouser",
      look: "Look 14",
    },
    {
      img: os22[55],
      webpimg: os22.w55,
      alt: "Tailored drawstring trousers",
      title: "Sand Slub drawstring trouser",
      look: "Look 14",
    },
    // {
    //   img: os22[56],
    //   webpimg: os22.w56,
    //   alt: "Tailored drawstring trousers",
    //   title: "Sand Slub drawstring trouser",
    //   look: "Look 14",
    // },
    {
      img: os22[57],
      webpimg: os22.w57,
      alt: "Tailored drawstring trousers",
      title: "Sand Slub drawstring trouser",
      look: "Look 14",
    },
  ],
  evento21: [
    {
      img: os21Evento[1],
      webpimg: os21Evento.w1,
      alt: "Bandhgala Jacket",
      title: "Bandhgala Jacket",
      look: "Look 1",
    },
    {
      img: os21Evento[2],
      webpimg: os21Evento.w2,
      alt: "Double breasted honeycomb jacket",
      title: "Bandhgala Jacket",
      look: "Look 1",
    },
    {
      img: os21Evento[3],
      webpimg: os21Evento.w3,
      alt: "Jodhpuri jacket",
      title: "Bandhgala Jacket",
      look: "Look 1",
    },
    // {
    //   img: os21Evento[4],
    //   webpimg: os21Evento.w4,
    //   alt: "Jodhpuri jacket",
    //   title: "Bandhgala Jacket",
    //   look: "Look 1",
    // },

    {
      img: os21Evento[5],
      webpimg: os21Evento.w5,
      alt: "Velvet blazers",
      title: "Liquid velvet jacket",
      look: "Look 2",
    },
    {
      img: os21Evento[6],
      webpimg: os21Evento.w6,
      alt: "Velvet blazers",
      title: "Liquid velvet jacket",
      look: "Look 2",
    },
    {
      img: os21Evento[7],
      webpimg: os21Evento.w7,
      alt: "Velvet blazers",
      title: "Liquid velvet jacket",
      look: "Look 2",
    },
    // {
    //   img: os21Evento[8],
    //   webpimg: os21Evento.w8,
    //   alt: "Velvet blazers",
    //   title: "Liquid velvet jacket",
    //   look: "Look 2",
    // },

    {
      img: os21Evento[9],
      webpimg: os21Evento.w9,
      alt: "Velvet blazers",
      title: "Liquid velvet jacket",
      look: "Look 3",
    },
    {
      img: os21Evento[10],
      webpimg: os21Evento.w10,
      alt: "Velvet blazers",
      title: "Liquid velvet jacket",
      look: "Look 3",
    },
    {
      img: os21Evento[11],
      webpimg: os21Evento.w11,
      alt: "Velvet blazers",
      title: "Liquid velvet jacket",
      look: "Look 3",
    },
    // {
    //   img: os21Evento[12],
    //   webpimg: os21Evento.w12,
    //   alt: "Velvet blazers",
    //   title: "Liquid velvet jacket",
    //   look: "Look 3",
    // },

    {
      img: os21Evento[13],
      webpimg: os21Evento.w13,
      alt: "Cashmere overcoat",
      title: "Overcoat",
      look: "Look 4",
    },
    {
      img: os21Evento[14],
      webpimg: os21Evento.w14,
      alt: "Cashmere overcoat",
      title: "Overcoat",
      look: "Look 4",
    },
    {
      img: os21Evento[15],
      webpimg: os21Evento.w15,
      alt: "Cashmere overcoat",
      title: "Overcoat",
      look: "Look 4",
    },
    // {
    //   img: os21Evento[16],
    //   webpimg: os21Evento.w16,
    //   alt: "Cashmere overcoat",
    //   title: "Overcoat",
    //   look: "Look 4",
    // },

    {
      img: os21Evento[17],
      webpimg: os21Evento.w17,
      alt: "Cashmere overcoat",
      title: "Overcoat",
      look: "Look 5",
    },
    {
      img: os21Evento[18],
      webpimg: os21Evento.w18,
      alt: "Cashmere overcoat",
      title: "Overcoat",
      look: "Look 5",
    },
    {
      img: os21Evento[19],
      webpimg: os21Evento.w19,
      alt: "Cashmere overcoat",
      title: "Overcoat",
      look: "Look 5",
    },
    // {
    //   img: os21Evento[20],
    //   webpimg: os21Evento.w20,
    //   alt: "Cashmere overcoat",
    //   title: "Overcoat",
    //   look: "Look 5",
    // },
  ],
  leisure21: [
    {
      img: leisure21[1],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 1",
    },
    {
      img: leisure21[2],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 1",
    },
    {
      img: leisure21[3],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 1",
    },
    // {
    //   img: leisure21[4],
    //   alt: "Luxury tracksuits",
    //   title: "Luxury leisurewear",
    //   look: "Look 1",
    // },

    {
      img: leisure21[5],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 2",
    },
    {
      img: leisure21[6],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 2",
    },
    {
      img: leisure21[7],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 2",
    },
    // {
    //   img: leisure21[8],
    //   alt: "Luxury tracksuits",
    //   title: "Luxury leisurewear",
    //   look: "Look 2",
    // },

    {
      img: leisure21[9],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 3",
    },
    {
      img: leisure21[10],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 3",
    },
    {
      img: leisure21[11],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 3",
    },
    // {
    //   img: leisure21[12],
    //   alt: "Luxury tracksuits",
    //   title: "Luxury leisurewear",
    //   look: "Look 3",
    // },

    {
      img: leisure21[13],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 4",
    },
    {
      img: leisure21[14],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 4",
    },
    {
      img: leisure21[15],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 4",
    },
    // {
    //   img: leisure21[16],
    //   alt: "Luxury tracksuits",
    //   title: "Luxury leisurewear",
    //   look: "Look 4",
    // },

    {
      img: leisure21[17],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 5",
    },
    {
      img: leisure21[18],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 5",
    },
    {
      img: leisure21[19],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 5",
    },
    // {
    //   img: leisure21[20],
    //   alt: "Luxury tracksuits",
    //   title: "Luxury leisurewear",
    //   look: "Look 5",
    // },
    {
      img: leisure21[21],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 6",
    },
    {
      img: leisure21[22],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 6",
    },
    {
      img: leisure21[23],
      alt: "Luxury tracksuits",
      title: "Luxury leisurewear",
      look: "Look 6",
    },
    // {
    //   img: leisure21[24],
    //   alt: "Luxury tracksuits",
    //   title: "Luxury leisurewear",
    //   look: "Look 6",
    // },
  ],
  os20: [
    {
      img: os20[1],
      alt: "Classic grey check suit",
      title: "Grey Prince of Wales check suit",
      look: "Look 1",
    },
    {
      img: os20[2],
      alt: "Classic grey check suit",
      title: "Grey Prince of Wales check suit",
      look: "Look 1",
    },
    {
      img: os20[3],
      alt: "Classic grey check suit",
      title: "Grey Prince of Wales check suit",
      look: "Look 1",
    },
    {
      img: os20[4],
      alt: "Double-breasted BLAZER",
      title: "Double breasted jacket",
      look: "Look 2",
    },

    {
      img: os20[5],
      alt: "Double-breasted BLAZER",
      title: "Double breasted jacket",
      look: "Look 2",
    },
    {
      img: os20[6],
      alt: "Double-breasted BLAZER",
      title: "Double breasted jacket",
      look: "Look 2",
    },
    {
      img: os20[7],
      alt: "Classic double-breasted suit",
      title: "Double-breasted (DB) suit",
      look: "Look 3",
    },
    {
      img: os20[8],
      alt: "Classic double-breasted suit",
      title: "Double-breasted (DB) suit",
      look: "Look 3",
    },

    {
      img: os20[9],
      alt: "Classic double-breasted suit",
      title: "Double-breasted (DB) suit",
      look: "Look 3",
    },
    {
      img: os20[10],
      alt: "Pin Stripe Blazer",
      title: "Pin Stripe Jacket",
      look: "Look 4",
    },
    {
      img: os20[11],
      alt: "Pin Stripe Blazer",
      title: "Pin Stripe Jacket",
      look: "Look 4",
    },
    {
      img: os20[12],
      alt: "Pin Stripe Blazer",
      title: "Pin Stripe Jacket",
      look: "Look 4",
    },

    {
      img: os20[13],
      alt: "Lapel Waistcoat",
      title: "Lapel Waistcoat",
      look: "Look 5",
    },
    {
      img: os20[14],
      alt: "Lapel Waistcoat",
      title: "Lapel Waistcoat",
      look: "Look 5",
    },
    {
      img: os20[15],
      alt: "Lapel Waistcoat",
      title: "Lapel Waistcoat",
      look: "Look 5",
    },
    {
      img: os20[16],
      alt: "Shirt Jacket",
      title: "Shirt Jacket",
      look: "Look 6",
    },

    {
      img: os20[17],
      alt: "Linen Shirt Jacket",
      title: "Shirt Jacket",
      look: "Look 6",
    },
    {
      img: os20[18],
      alt: "Linen Shirt Jacket",
      title: "Shirt Jacket",
      look: "Look 6",
    },
    {
      img: os20[19],
      alt: "Classic grey check suit",
      title: "Grey Prince of Wales check suit",
      look: "Look 7",
    },
    {
      img: os20[20],
      alt: "Classic grey check suit",
      title: "Grey Prince of Wales check suit",
      look: "Look 7",
    },
    {
      img: os20[21],
      alt: "Classic grey check suit",
      title: "Grey Prince of Wales check suit",
      look: "Look 7",
    },
    {
      img: os20[22],
      alt: "Evening Gilet Waistcoats",
      title: "Evening Waistcoat",
      look: "Look 8",
    },
    {
      img: os20[23],
      alt: "Evening Gilet Waistcoat",
      title: "Evening Waistcoat",
      look: "Look 8",
    },
    {
      img: os20[24],
      alt: "Evening Gilet Waistcoat",
      title: "Evening Waistcoat",
      look: "Look 8",
    },
    {
      img: os20[25],
      alt: "Classic tuxedo",
      title: "007 tuxedo suit",
      look: "Look 9",
    },
    {
      img: os20[26],
      alt: "Classic tuxedo",
      title: "007 tuxedo suit",
      look: "Look 9",
    },
    {
      img: os20[27],
      alt: "Classic tuxedo",
      title: "007 tuxedo suit",
      look: "Look 9",
    },
    {
      img: os20[28],
      alt: "Classic tuxedo",
      title: "007 tuxedo suit",
      look: "Look 10",
    },
    {
      img: os20[29],
      alt: "Classic tuxedo",
      title: "007 tuxedo suit",
      look: "Look 10",
    },
    {
      img: os20[30],
      alt: "Classic tuxedo",
      title: "007 tuxedo suit",
      look: "Look 10",
    },
    {
      img: os20[31],
      alt: "Classic tuxedo",
      title: "Classic tuxedo",
      look: "Look 11",
    },
    {
      img: os20[32],
      alt: "007 tuxedo suit",
      title: "Classic tuxedo",
      look: "Look 11",
    },
    {
      img: os20[33],
      alt: "Classic tuxedo",
      title: "Classic tuxedo",
      look: "Look 11",
    },
  ],
};
