import React from "react";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ReactComponent as Inbox } from "../assets/Vector.svg";
import { ReactComponent as FB } from "../assets/FB.svg";
import { ReactComponent as Insta } from "../assets/Insta.svg";
import { ReactComponent as WhatsApp } from "../assets/WA.svg";
import logo from "../assets/LogoBlack.png";
import { useNavigate } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";

function Footer() {
  const navigate = useNavigate();

  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: "Melbourne",
          },
        },
      },
    },
  });

  const collection = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return navigate("/Collection", { state: { type: "os22", row: 4 } })[
      navigate
    ];
  };

  const shop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return navigate("/Shop")[navigate];
  };

  const trunkshow = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return navigate("/TrunkShow")[navigate];
  };

  const press = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return navigate("/Press")[navigate];
  };
  const celeb = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return navigate("/Celebrities")[navigate];
  };
  const contact = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return navigate("/Contact")[navigate];
  };
  return (
    <React.Fragment>
      <div className="Footer">
        <div className="Links">
          <ThemeProvider theme={theme}>
            <List>
              <ListItem disablePadding>
                <h3>QUICK LINKS</h3>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={collection}>
                  <ListItemText primary="COLLECTION" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={shop}>
                  <ListItemText primary="SHOP" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={trunkshow}>
                  <ListItemText primary="TRUNK SHOW" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={press}>
                  <ListItemText primary="PRESS" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={celeb}>
                  <ListItemText primary="CELEBRITIES" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={contact}>
                  <ListItemText primary="CONTACT" />
                </ListItemButton>
              </ListItem>
            </List>
          </ThemeProvider>
        </div>
        <div className="Locations">
          <List>
            <ListItem disablePadding>
              <h3>LOCATION</h3>
            </ListItem>
            <ListItem disablePadding>
              <p>
                11 ANNEBELLE CHS, 81SV ROAD ,<br></br> SANTACRUZ WEST, MUMBAI,
                <br></br> MAHARASHTRA 400054.
              </p>
            </ListItem>
            <ListItem disablePadding>
              <h3>EMAIL</h3>
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <Inbox></Inbox>
              </ListItemIcon>
              <span id="emailfooter">
                <a href="mailto:CONTACT@OSBYOS.COM">CONTACT@OSBYOS.COM</a>
              </span>
            </ListItem>
            <ListItem disablePadding>
              <h3>Contact Number:</h3>
            </ListItem>
            <ListItem disablePadding>
              <span>
                <a href="tel:+919833097025">9833097025</a>
              </span>
            </ListItem>
          </List>
        </div>
        <div className="Connect">
          <List>
            <ListItem disablePadding>
              <h3>CONNECT WITH US</h3>
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <a
                  href="https://www.facebook.com/osbyosofficial?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FB></FB>
                </a>
              </ListItemIcon>
              <ListItemIcon>
                <a
                  href="https://instagram.com/osbyos?igshid=ZDdkNTZiNTM="
                  target="_blank"
                  rel="noreferrer"
                >
                  <Insta></Insta>
                </a>
              </ListItemIcon>
              <ListItemIcon>
                <a
                  href="https://wa.me/9833097025"
                  target="_blank"
                  rel="noreferrer"
                >
                  <WhatsApp></WhatsApp>
                </a>
              </ListItemIcon>
            </ListItem>
          </List>
        </div>
        <div className="Logo">
          <List>
            <ListItem sx={{ pt: 3 }} disablePadding>
              <img
                src={logo}
                alt="OSBYOS LOGO"
                width="100vw"
                height="auto"
                loading="lazy"
              ></img>
            </ListItem>
          </List>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
