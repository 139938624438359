import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { HomeImages } from "../Images";

export default function MasonryImageList() {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <ImageList variant="masonry" cols={3} gap={5}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: HomeImages[1],
    title: "Bed",
    description: "bed",
  },
  {
    img: HomeImages[2],
    title: "Books",
  },
  {
    img: HomeImages[3],
    title: "Sink",
  },
  {
    img: HomeImages[4],
    title: "Kitchen",
  },
  {
    img: HomeImages[5],
    title: "Blinds",
  },
  {
    img: HomeImages[6],
    title: "Chairs",
  },
  {
    img: HomeImages[7],
    title: "Laptop",
  },
  {
    img: HomeImages[8],
    title: "Doors",
  },
  {
    img: HomeImages[9],
    title: "Coffee",
  },
  {
    img: HomeImages[10],
    title: "Storage",
  },
  {
    img: HomeImages[11],
    title: "Candle",
  },
  {
    img: HomeImages[12],
    title: "Coffee table",
  },
  {
    img: HomeImages[13],
    title: "Coffee table",
  },
  {
    img: HomeImages[14],
    title: "Coffee table",
  },
  {
    img: HomeImages[15],
    title: "Coffee table",
  },
];
