import React from "react";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";

import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";

const black = {
  500: "#010203",
  600: "#121212",
  700: "#232323",
};

function Contact() {
  return (
    <div className="TrunkShow">
      <div>
        <h1>OS BY OS TRUNK SHOW</h1>
        <p>
          <b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            OSBYOS trunk shows are by invitation-only events, held for a limited
            time in selected cities and exclusive locations. They offer a great
            client experience, allowing attendees to get a first look at our
            limited edition collections and fabrics from mills around the world
            that may not be available elsewhere.
          </b>
        </p>
        <div>
          <Typography variant="h6">UAE</Typography>

          <Typography>DUBAI, ABU DHABI, QATAR</Typography>
          <Divider />

          <Typography variant="h6">KSA</Typography>

          <Typography>RIYADH & JEDDAH</Typography>
          <Divider />

          <Typography variant="h6">UK</Typography>

          <Typography>LONDON</Typography>
          <Divider />

          <Typography variant="h6">SINGAPORE</Typography>
          <Divider />

          <Typography variant="h6">INDIA</Typography>

          <Typography>
            NEW DELHI, BENGALURU, CHENNAI, HYDERABAD, KOLKATTA, INDORE, RAIPUR,
            PUNE.
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Contact;
