import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { celebrities } from "../Images";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          fontFamily: "Melbourne",
          background: "#fdfff5",
          width: "100%",
          height: "auto",
          margin: "5%",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          width: "50%",
        },
      },
    },
  },
});

theme.typography.h6 = {
  fontSize: "0.7rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
};

export default function Celebrities() {
  return (
    <div className="celebrities">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          sx={{ justifyContent: "center" }}
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {itemData.map((item, index) => (
            <Grid item xs={12} sm={5} md={5} key={index}>
              <Card theme={theme}>
                <div className="CelebrityDiv">
                  <CardContent theme={theme}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        sx={{ color: "#FEFEFE" }}
                        gutterBottom
                        variant="h6"
                        component="div"
                      >
                        {item.title}
                      </Typography>
                    </ThemeProvider>
                  </CardContent>
                  <div style={{ width: "80%" }}>
                    <img src={item.img} alt={item.title} width="100%"></img>
                  </div>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

const itemData = [
  {
    img: celebrities.srk5,
    title:
      "SRK is wearing our bespoke virgin wool window pane checks jacket, charcoal grey wool waistcoat, and trousers for the event in Melbourne.",
  },
  {
    img: celebrities.virat,
    title:
      "Virat Kohli is wearing our bespoke navy blue Prince of Wales check suit in Cacciopoli fabric, paired with a flannel chocolate brown vest (waistcoat) and a poplin white shirt for his wedding.",
  },

  {
    img: celebrities.virat1,
    title:
      "Virat kohli wearing our custom made black wool shawl lapel 2pc tuxedo and white shirt with classic collar and diamond cut handmade bow tie for the indian sports honours awards show",
  },
  {
    img: celebrities.virat2,
    title:
      "Virat Kohli is wearing our bespoke worsted wool flannel suit and a classic white poplin shirt for Zaheer Khan's wedding reception.",
  },
  {
    img: celebrities.srk1,
    title:
      "Shahrukh Khan is wearing our custom-made double black notch lapel 2-piece suit with handmade buttonholes at the Crystal Award ceremony during the World Economic Forum in Davos, Switzerland.",
  },
  {
    img: celebrities.srk2,
    title:
      "Shahrukh Khan wearing Osbyos custom-made olive green wool 3pc suit at a ceremony by Burjeel Holdings as he was appointed their brand ambassador. ",
  },
  {
    img: celebrities.srk3,
    title:
      "Shahrukh Khan is wearing our bespoke evening wool mohair tuxedo with natural shoulders, a handmade bowtie, and an evening shirt with a bib for the Vogue Women of the Year Awards",
  },
  {
    img: celebrities.aparshakti,
    title:
      "Aparshakti Khurrana in Osbyos bespoke liquid velvet jacket, evening shirt with bib, evening wool canvas trouser, handmade bowtie, and cummerbund for IIFA Awards.",
  },
  {
    img: celebrities.ayushman1,
    title:
      "Ayushmann Khurrana is wearing our metallic silk jacquard dinner jacket with contrast black satin lapel and white trousers for the Filmfare cover shoot.",
  },
  {
    img: celebrities.dilquer,
    title:
      "Dulquer Salmaan is wearing our bespoke liquid velvet jacket, black cotton shirt, and evening wool canvas trousers for the Petrolhead Actor of the Year award at the BBC Top Gear Awards.",
  },
  {
    img: celebrities.armaan,
    title:
      "Armaan Malik wearing Osbyos custom-made 6-button double-breasted midnight blue suit in sharkskin wool for the GQ digital cover shoot.",
  },

  {
    img: celebrities.vicky,
    title:
      "Vicky Kaushal is wearing our custom-made 6-button double-breasted grey 2-piece suit in Scabal fabric for the Mansworld cover shoot.",
  },
];
