import React from "react";
import { useNavigate } from "react-router-dom";
import HeroImg from "../assets/OS BY OS FALL WINTER 22-23 27 AUGUST 20220737.webp";
import logo from "../assets/OSBYOSLogo.jpg";
import TitlebarImageList from "./HomeImages.js";
import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
// import Particle from "./Particle";

const black = {
  500: "#010203",
  600: "#121212",
  700: "#232323",
};

const CustomButton = styled(ButtonUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${black[500]};
  padding: 12px 24px;
  border-radius: 12px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${black[600]};
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${black[700]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function Home() {
  const navigate = useNavigate();
  const AboutUs = () => {
    return navigate("/AboutUs", {})[navigate];
  };

  return (
    <React.Fragment>
      <div className="HeroImg">
        <img
          src={HeroImg}
          alt="OS BY OS FALL WINTER"
          width={"100%"}
          height={"100%"}
        ></img>
      </div>
      <div className="AboutDiv">
        {/* <div className="AboutLogo">
          <img
            src={logo}
            alt="OSBYOS LOGO"
            width="100%"
            height="auto"
            loading="lazy"
          ></img>
        </div> */}

        <div className="AboutPara">
          <p>
            OSBYOS is a bespoke tailoring brand that upholds the finest
            tailoring traditions, following some of the best practices around
            the world in custom menswear. After having worked with some of the
            biggest names in bespoke menswear like Tom Ford and Ermenegildo
            Zegna for over a decade, the founder, Owais Shaikh – also the winner
            of GQ magazine’s best dressed men award in 2015 – decided to use his
            experience to foster his own vision through OSBYOS.
            <br></br>
            <br></br>
          </p>
        </div>
      </div>
      <div className="KnowMore">
        <Stack spacing={2} direction="row">
          <CustomButton onClick={AboutUs}>KNOW MORE</CustomButton>
        </Stack>
      </div>
      <TitlebarImageList></TitlebarImageList>
    </React.Fragment>
  );
}

export default Home;
