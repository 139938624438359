import React, { useRef, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom";

import MapWithMarker from "./Map";

const black = {
  500: "#010203",
  600: "#121212",
  700: "#232323",
};

const CustomButton = styled(ButtonUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${black[500]};
  padding: 12px 24px;
  border-radius: 12px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${black[600]};
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${black[700]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function Contact() {
  const form = useRef();
  const [reason, setReason] = React.useState("Product Information");

  const location = useLocation();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kzox1xm",
        "template_vxb5zct",
        form.current,
        "y14FG06q_anoleWO0"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
          setReason("");
          alert("SUCCESS!");
        },
        (error) => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
  };

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const markerCoordinates = [51.505, -0.09]; // Latitude, Longitude

  return (
    <div className="contact">
      <div className="contactdiv KnowMore">
        <Card sx={{ width: "100%", padding: "5%" }}>
          <CardContent>
            <form ref={form} onSubmit={sendEmail}>
              <Typography
                sx={{ fontSize: "0.8rem" }}
                color="text.secondary"
                gutterBottom
              >
                Name
              </Typography>
              <TextField
                sx={{ width: "100%" }}
                required
                size="small"
                name="from_name"
                id="outlined-required"
              />{" "}
              <br></br>
              <br></br>
              <Typography
                sx={{ fontSize: "0.8rem" }}
                color="text.secondary"
                gutterBottom
              >
                Email
              </Typography>
              <TextField
                sx={{ width: "100%" }}
                name="from_email"
                size="small"
                required
                id="outlined-required"
              />{" "}
              <br></br>
              <br></br>
              <Typography
                sx={{ fontSize: "0.8rem" }}
                color="text.secondary"
                gutterBottom
              >
                Contact
              </Typography>
              <TextField
                sx={{ width: "100%" }}
                required
                size="small"
                name="from_contact"
                id="outlined-required"
              />{" "}
              {location.state && (
                <>
                  <TextField
                    sx={{ display: "none" }}
                    name="description"
                    size="small"
                    value={location.state.title}
                    required
                    id="outlined-required"
                  />{" "}
                  <TextField
                    sx={{ display: "none" }}
                    name="look"
                    size="small"
                    value={location.state.look}
                    required
                    id="outlined-required"
                  />{" "}
                  <TextField
                    sx={{ display: "none" }}
                    name="collection"
                    size="small"
                    required
                    value={location.state.collection}
                    id="outlined-required"
                  />{" "}
                  <br></br>
                  <br></br>
                  <Typography
                    sx={{ fontSize: "0.8rem" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Reason
                  </Typography>
                  <Select
                    sx={{ width: "100%" }}
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reason}
                    label="Reason"
                    size="small"
                    name="Reason"
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="Product Information">
                      Product Information
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {""}
                </>
              )}
              <br></br>
              <br></br>
              <Typography
                sx={{ fontSize: "0.8rem" }}
                color="text.secondary"
                gutterBottom
              >
                Message
              </Typography>
              <TextareaAutosize
                required
                aria-label="empty textarea"
                label="Message"
                name="message"
                size="small"
                minRows={4}
                placeholder="Type your message here.."
                style={{ width: "100%" }}
              />
              <br></br>
              <br></br>
              <div className="KnowMore">
                <Stack spacing={2} direction="row">
                  <CustomButton type="submit">SUBMIT</CustomButton>
                </Stack>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
      <div className="contactdiv">
        <h1>WE ARE HERE FOR YOU.</h1>
        <p>
          11 Annebelle CHS, 81SV Road ,<br></br> Santacruz West, Mumbai,
          <br></br> Maharashtra 400054.
          <br></br> <a href="tel:+919833097025">Contact Number: 9833097025</a>
        </p>
        <div class="mapouter">
          <div class="gmap_canvas">
            <MapWithMarker markerCoordinates={[19.07928, 72.838]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
