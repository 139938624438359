import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import "./App.css";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import Collection from "./components/Collection";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Celebrities from "./components/Celebrities";
import Shop from "./components/Shop";
import TrunkShow from "./components/TrunkShow";
import Press from "./components/Press";

function App() {
  return (
    <div className="App">
      <Router>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Collection" element={<Collection />} />
          <Route path="/Celebrities" element={<Celebrities />} />
          <Route path="/Shop" element={<Shop />} />
          <Route path="/TrunkShow" element={<TrunkShow />} />
          <Route path="/Press" element={<Press />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
