// MapWithMarker.js

import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const MapWithMarker = ({ markerCoordinates }) => {
  return (
    <MapContainer
      center={markerCoordinates}
      zoom={20}
      scrollWheelZoom={true}
      style={{
        height: "400px",
        backgroundColor: "#fff",
        marginTop: "80px",
        marginBottom: "90px",
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={markerCoordinates}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapWithMarker;
