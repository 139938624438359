import React from "react";
import { mills } from "../Images";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const imgArr = [
  {
    img: mills[1],
  },
  {
    img: mills[2],
  },
  {
    img: mills[3],
  },
  {
    img: mills[4],
  },
  {
    img: mills[5],
  },
  {
    img: mills[6],
  },
  {
    img: mills[7],
  },
  {
    img: mills[8],
  },
  {
    img: mills[9],
  },
  {
    img: mills[10],
  },
];

function AboutUs() {
  return (
    <div className="About">
      <div className="AboutUs">
        <div className="KnowMore">
          <h1>ABOUT US</h1>
        </div>
        <div className="AboutPara">
          <p>
            OSBYOS is a bespoke tailoring brand that upholds the finest
            tailoring traditions, following some of the best practices around
            the world in custom menswear. After having worked with some of the
            biggest names in bespoke menswear like Tom Ford and Ermenegildo
            Zegna for over a decade, the founder, Owais Shaikh – also the winner
            of GQ magazine’s best dressed men award in 2015 – decided to use his
            experience to foster his own vision through OSBYOS..
            <br></br>
            <br></br>
            With every commissioned outfit, OSBYOS believes in giving the client
            an opportunity to look and feel amazing. Each garment is created
            with incredible care, using techniques that are meticulously
            researched.<br></br>
            <br></br>
            OSBYOS has had the opportunity to dress some of the biggest names in
            India, including Shah Rukh Khan, Karan Johar, and Virat Kohli, on
            multiple occasions. Perhaps the next one could be you!
          </p>
        </div>
      </div>
      <div className="Story">
        <div className="KnowMore">
          <h1>STORY</h1>
        </div>
        <div className="AboutPara">
          <p>
            Like the curiosity and inquisitiveness that drive a child, there was
            a constant search for knowledge about how a well-fitted suit is made
            and what goes into creating a sharp look in our founder. He began
            the journey by first deconstructing the suit and technically
            studying its various aspects. After mastering the classic suit, he
            moved towards experimenting – not only with the physical parts of a
            suit but also with hundreds of other steps that go into creating the
            suit to come up with innumerable permutations and combinations of
            putting things together in a particular way. OSBYOS was born in 2015
            with the quest to find the best shirt, trouser, and suit. Having
            meticulously mastered every aspect, OSBYOS offers only the best when
            it comes to luxury bespoke menswear in India
          </p>
        </div>
      </div>
      <div className="Bespoke">
        <div className="KnowMore">
          <h1>BESPOKE PROCESS</h1>
        </div>
        <div className="AboutPara">
          <p>
            Every outfit that OSBYOS develops is handcrafted with a lot of care
            and diligence using only 100% natural materials to make it unique
            for the person who has commissioned it. OSBYOS does not use the
            fused or glued interlining technique, which came into being as a
            mass production technique after World War II for its jackets and
            suits. Our process of jacket-making is inspired from the pre-war era
            of natural horse/camel hair interlining, commonly known as the
            floating canvas construction of jackets. For the jacket, hundreds of
            stitches are executed by hand across the chest piece to make sure
            that the roll of the lapel is natural and not artificial. The
            shoulder pads are assiduously picked to be extremely lightweight and
            naturally sloping. The ergonomics of our trouser provide maximum
            comfort through every movement and naturally shape the waist.
          </p>
        </div>
      </div>
      <div className="Collection">
        <div className="KnowMore">
          <h1>COLLECTION</h1>
        </div>
        <div className="AboutPara">
          <p>
            Keeping the aesthetic of the 1950s and 1960s, with broad lapels and
            high-waist trousers in mind, OSBYOS believes in keeping things clean
            and simple to deliver utmost comfort while staying true to the
            philosophy of the suit. Every garment is tested in such a way that
            it would be on-trend ten years ago or ten years hence, making the
            collection timeless. Each outfit is meant to last longer; it is an
            investment that isn't just for the moment, but one that you can
            create countless memories with.
          </p>
        </div>
      </div>
      <div className="Mills">
        <div className="KnowMore">
          <h1>MILLS</h1>
        </div>
        <ImageList
          variant="masonry"
          gap={8}
          sx={{ width: "100%", height: "100%" }}
          cols={5}
        >
          {imgArr.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=100&h=100&fit=crop&auto=format`}
                srcSet={`${item.img}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                alt="Mills Name"
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
}

export default AboutUs;
