const HomeImages = {
  1: require("./assets/HomeImages/_Liquid velvet jacket.webp"),
  2: require("./assets/HomeImages/ayushmann khurrana(1).webp"),
  3: require("./assets/HomeImages/ayushmann khurrana.webp"),
  4: require("./assets/HomeImages/Bandhgala Jacket.webp"),
  5: require("./assets/HomeImages/BOW TIE _ POCKET SQUARE.webp"),
  6: require("./assets/HomeImages/CLASSIC GREY CHECK SUIT.webp"),
  7: require("./assets/HomeImages/DENIM SHIRT.webp"),
  8: require("./assets/HomeImages/Double-breasted BLAZER.webp"),
  9: require("./assets/HomeImages/Drawstring trousers.webp"),
  10: require("./assets/HomeImages/Evening Waistcoat.webp"),
  11: require("./assets/HomeImages/Liquid velvet bandhgala.webp"),
  12: require("./assets/HomeImages/Luxury leisurewear.webp"),
  13: require("./assets/HomeImages/Metallic suit jacket.webp"),
  14: require("./assets/HomeImages/Pin Stripe Jacket.webp"),
  15: require("./assets/HomeImages/trucker jacket.webp"),
  16: require("./assets/HomeImages/trucker jacket.webp"),
};

const mills = {
  1: require("./assets/MillsImages/01-VoceDi-Loro-Piana-fabric_cropscale_832x537.webp"),
  2: require("./assets/MillsImages/308387308_580779010407154_5288955670012138670_n.webp"),
  3: require("./assets/MillsImages/about-us-pricing_clip_image007.webp"),
  4: require("./assets/MillsImages/Cloth EZegna+CoccardJJ(1).webp"),
  5: require("./assets/MillsImages/drago-lanificio-in-biella-logo.webp"),
  6: require("./assets/MillsImages/Logo_-_Copia.webp"),
  7: require("./assets/MillsImages/logo_references_alumo.webp"),
  8: require("./assets/MillsImages/logoweb.webp"),
  9: require("./assets/MillsImages/solbiati-logo.webp"),
  10: require("./assets/MillsImages/TM_MASTER_LOGO_CMYK_oriz.webp"),
};

const os22 = {
  1: require("./assets/os 22/1/OS BY OS FALL WINTER 22-23 27 AUGUST 20220144-min.jpg"),
  2: require("./assets/os 22/1/OS BY OS FALL WINTER 22-23 27 AUGUST 20220152-min.jpg"),
  3: require("./assets/os 22/1/OS BY OS FALL WINTER 22-23 27 AUGUST 20220243-min.webp"),
  4: require("./assets/os 22/1/OS BY OS FALL WINTER 22-23 27 AUGUST 20220174-min.webp"),
  5: require("./assets/os 22/4/OS BY OS FALL WINTER 22-23 27 AUGUST 20220018-min.jpg"),
  6: require("./assets/os 22/4/OS BY OS FALL WINTER 22-23 27 AUGUST 20220055-min.jpg"),
  7: require("./assets/os 22/4/OS BY OS FALL WINTER 22-23 27 AUGUST 20220056-min.jpg"),
  8: require("./assets/os 22/4/OS BY OS FALL WINTER 22-23 27 AUGUST 20220070-min.jpg"),
  9: require("./assets/os 22/5/Copy of OS BY OS FALL WINTER 22-23 27 AUGUST 20220626-min.jpg"),
  10: require("./assets/os 22/5/OS BY OS FALL WINTER 22-23 27 AUGUST 20220598-min.jpg"),
  11: require("./assets/os 22/5/OS BY OS FALL WINTER 22-23 27 AUGUST 20220600-min.jpg"),
  12: require("./assets/os 22/5/OS BY OS FALL WINTER 22-23 27 AUGUST 20220615-min.jpg"),
  13: require("./assets/os 22/6/OS BY OS FALL WINTER 22-23 27 AUGUST 20220522-min.jpg"),
  14: require("./assets/os 22/6/OS BY OS FALL WINTER 22-23 27 AUGUST 20220534 copy-min.jpg"),
  15: require("./assets/os 22/6/OS BY OS FALL WINTER 22-23 27 AUGUST 20220538-min.jpg"),
  17: require("./assets/os 22/6/OS BY OS FALL WINTER 22-23 27 AUGUST 20220542-min.jpg"),
  18: require("./assets/os 22/7/OS BY OS FALL WINTER 22-23 27 AUGUST 20220661-min.jpg"),
  19: require("./assets/os 22/7/OS BY OS FALL WINTER 22-23 27 AUGUST 20220676-min.jpg"),
  20: require("./assets/os 22/7/OS BY OS FALL WINTER 22-23 27 AUGUST 20220715-min.jpg"),
  21: require("./assets/os 22/7/OS BY OS FALL WINTER 22-23 27 AUGUST 20220723-min.jpg"),
  22: require("./assets/os 22/8/OS BY OS FALL WINTER 22-23 27 AUGUST 20220293-min.jpg"),
  23: require("./assets/os 22/8/OS BY OS FALL WINTER 22-23 27 AUGUST 20220351-min.jpg"),
  24: require("./assets/os 22/8/OS BY OS FALL WINTER 22-23 27 AUGUST 20220428-min.jpg"),
  25: require("./assets/os 22/8/OS BY OS FALL WINTER 22-23 27 AUGUST 20220504-min.jpg"),
  26: require("./assets/os 22/9/OS BY OS FALL WINTER 22-23 27 AUGUST 20220837-min.jpg"),
  27: require("./assets/os 22/9/OS BY OS FALL WINTER 22-23 27 AUGUST 20220854-min.jpg"),
  28: require("./assets/os 22/9/OS BY OS FALL WINTER 22-23 27 AUGUST 20220905-min.jpg"),
  29: require("./assets/os 22/9/OS BY OS FALL WINTER 22-23 27 AUGUST 20220938-min.jpg"),
  30: require("./assets/os 22/10/OS BY OS FALL WINTER 22-23 27 AUGUST 20220990-min.jpg"),
  31: require("./assets/os 22/10/OS BY OS FALL WINTER 22-23 27 AUGUST 20220997-min.jpg"),
  32: require("./assets/os 22/10/OS BY OS FALL WINTER 22-23 27 AUGUST 20221002-min.jpg"),
  33: require("./assets/os 22/10/OS BY OS FALL WINTER 22-23 27 AUGUST 20221017-min.jpg"),
  34: require("./assets/os 22/11/OS BY OS FALL WINTER 22-23 27 AUGUST 20221058-min.jpg"),
  35: require("./assets/os 22/11/OS BY OS FALL WINTER 22-23 27 AUGUST 20221074-min.jpg"),
  36: require("./assets/os 22/11/OS BY OS FALL WINTER 22-23 27 AUGUST 20221114-min.jpg"),
  37: require("./assets/os 22/11/OS BY OS FALL WINTER 22-23 27 AUGUST 20221136-min.jpg"),
  38: require("./assets/os 22/12/OS BY OS FALL WINTER 22-23 27 AUGUST 20221421-min.jpg"),
  39: require("./assets/os 22/12/OS BY OS FALL WINTER 22-23 27 AUGUST 20221519-min.jpg"),
  40: require("./assets/os 22/12/OS BY OS FALL WINTER 22-23 27 AUGUST 20221582-min.jpg"),
  41: require("./assets/os 22/12/OS BY OS FALL WINTER 22-23 27 AUGUST 20221675-min.jpg"),
  42: require("./assets/os 22/13/Copy of OS BY OS FALL WINTER 22-23 27 AUGUST 20221754-min.jpg"),
  43: require("./assets/os 22/13/OS BY OS FALL WINTER 22-23 27 AUGUST 20221744-min.jpg"),
  44: require("./assets/os 22/13/OS BY OS FALL WINTER 22-23 27 AUGUST 20221756-min.jpg"),
  45: require("./assets/os 22/13/OS BY OS FALL WINTER 22-23 27 AUGUST 20221774-min.jpg"),
  46: require("./assets/os 22/14/OS BY OS FALL WINTER 22-23 27 AUGUST 20221781-min.jpg"),
  47: require("./assets/os 22/14/OS BY OS FALL WINTER 22-23 27 AUGUST 20221824-min.jpg"),
  48: require("./assets/os 22/14/OS BY OS FALL WINTER 22-23 27 AUGUST 20221854 copy-min.jpg"),
  49: require("./assets/os 22/14/OS BY OS FALL WINTER 22-23 27 AUGUST 20221926-min.jpg"),
  50: require("./assets/os 22/15/OS BY OS FALL WINTER 22-23 27 AUGUST 20221211-min.jpg"),
  51: require("./assets/os 22/15/OS BY OS FALL WINTER 22-23 27 AUGUST 20221237-min.jpg"),
  52: require("./assets/os 22/15/OS BY OS FALL WINTER 22-23 27 AUGUST 20221273-min.jpg"),
  53: require("./assets/os 22/15/OS BY OS FALL WINTER 22-23 27 AUGUST 20221293-min.jpg"),
  54: require("./assets/os 22/16/OS BY OS FALL WINTER 22-23 27 AUGUST 20221329-min.jpg"),
  55: require("./assets/os 22/16/OS BY OS FALL WINTER 22-23 27 AUGUST 20221342-min.jpg"),
  56: require("./assets/os 22/16/OS BY OS FALL WINTER 22-23 27 AUGUST 20221358-min.jpg"),
  57: require("./assets/os 22/16/OS BY OS FALL WINTER 22-23 27 AUGUST 20221362-min.jpg"),
  w1: require("./assets/os 22/1/OS BY OS FALL WINTER 22-23 27 AUGUST 20220144-min.webp"),
  w2: require("./assets/os 22/1/OS BY OS FALL WINTER 22-23 27 AUGUST 20220152-min.webp"),
  w3: require("./assets/os 22/1/OS BY OS FALL WINTER 22-23 27 AUGUST 20220174-min.webp"),
  w4: require("./assets/os 22/1/OS BY OS FALL WINTER 22-23 27 AUGUST 20220243-min.webp"),
  w5: require("./assets/os 22/4/OS BY OS FALL WINTER 22-23 27 AUGUST 20220018-min.webp"),
  w6: require("./assets/os 22/4/OS BY OS FALL WINTER 22-23 27 AUGUST 20220055-min.webp"),
  w7: require("./assets/os 22/4/OS BY OS FALL WINTER 22-23 27 AUGUST 20220056-min.webp"),
  w8: require("./assets/os 22/4/OS BY OS FALL WINTER 22-23 27 AUGUST 20220070-min.webp"),
  w9: require("./assets/os 22/5/Copy of OS BY OS FALL WINTER 22-23 27 AUGUST 20220626-min.webp"),
  w10: require("./assets/os 22/5/OS BY OS FALL WINTER 22-23 27 AUGUST 20220598-min.webp"),
  w11: require("./assets/os 22/5/OS BY OS FALL WINTER 22-23 27 AUGUST 20220600-min.webp"),
  w12: require("./assets/os 22/5/OS BY OS FALL WINTER 22-23 27 AUGUST 20220615-min.webp"),
  w13: require("./assets/os 22/6/OS BY OS FALL WINTER 22-23 27 AUGUST 20220522-min.webp"),
  w14: require("./assets/os 22/6/OS BY OS FALL WINTER 22-23 27 AUGUST 20220534 copy-min.webp"),
  w15: require("./assets/os 22/6/OS BY OS FALL WINTER 22-23 27 AUGUST 20220538-min.webp"),
  w17: require("./assets/os 22/6/OS BY OS FALL WINTER 22-23 27 AUGUST 20220542-min.webp"),
  w18: require("./assets/os 22/7/OS BY OS FALL WINTER 22-23 27 AUGUST 20220661-min.webp"),
  w19: require("./assets/os 22/7/OS BY OS FALL WINTER 22-23 27 AUGUST 20220676-min.webp"),
  w20: require("./assets/os 22/7/OS BY OS FALL WINTER 22-23 27 AUGUST 20220715-min.webp"),
  w21: require("./assets/os 22/7/OS BY OS FALL WINTER 22-23 27 AUGUST 20220723-min.webp"),
  w22: require("./assets/os 22/8/OS BY OS FALL WINTER 22-23 27 AUGUST 20220293-min.webp"),
  w23: require("./assets/os 22/8/OS BY OS FALL WINTER 22-23 27 AUGUST 20220351-min.webp"),
  w24: require("./assets/os 22/8/OS BY OS FALL WINTER 22-23 27 AUGUST 20220428-min.webp"),
  w25: require("./assets/os 22/8/OS BY OS FALL WINTER 22-23 27 AUGUST 20220504-min.webp"),
  w26: require("./assets/os 22/9/OS BY OS FALL WINTER 22-23 27 AUGUST 20220837-min.webp"),
  w27: require("./assets/os 22/9/OS BY OS FALL WINTER 22-23 27 AUGUST 20220854-min.webp"),
  w28: require("./assets/os 22/9/OS BY OS FALL WINTER 22-23 27 AUGUST 20220905-min.webp"),
  w29: require("./assets/os 22/9/OS BY OS FALL WINTER 22-23 27 AUGUST 20220938-min.webp"),
  w30: require("./assets/os 22/10/OS BY OS FALL WINTER 22-23 27 AUGUST 20220990-min.webp"),
  w31: require("./assets/os 22/10/OS BY OS FALL WINTER 22-23 27 AUGUST 20220997-min.webp"),
  w32: require("./assets/os 22/10/OS BY OS FALL WINTER 22-23 27 AUGUST 20221002-min.webp"),
  w33: require("./assets/os 22/10/OS BY OS FALL WINTER 22-23 27 AUGUST 20221017-min.webp"),
  w34: require("./assets/os 22/11/OS BY OS FALL WINTER 22-23 27 AUGUST 20221058-min.webp"),
  w35: require("./assets/os 22/11/OS BY OS FALL WINTER 22-23 27 AUGUST 20221074-min.webp"),
  w36: require("./assets/os 22/11/OS BY OS FALL WINTER 22-23 27 AUGUST 20221114-min.webp"),
  w37: require("./assets/os 22/11/OS BY OS FALL WINTER 22-23 27 AUGUST 20221136-min.webp"),
  w38: require("./assets/os 22/12/OS BY OS FALL WINTER 22-23 27 AUGUST 20221421-min.webp"),
  w39: require("./assets/os 22/12/OS BY OS FALL WINTER 22-23 27 AUGUST 20221519-min.webp"),
  w40: require("./assets/os 22/12/OS BY OS FALL WINTER 22-23 27 AUGUST 20221582-min.webp"),
  w41: require("./assets/os 22/12/OS BY OS FALL WINTER 22-23 27 AUGUST 20221675-min.webp"),
  w42: require("./assets/os 22/13/Copy of OS BY OS FALL WINTER 22-23 27 AUGUST 20221754-min.webp"),
  w43: require("./assets/os 22/13/OS BY OS FALL WINTER 22-23 27 AUGUST 20221744-min.webp"),
  w44: require("./assets/os 22/13/OS BY OS FALL WINTER 22-23 27 AUGUST 20221756-min.webp"),
  w45: require("./assets/os 22/13/OS BY OS FALL WINTER 22-23 27 AUGUST 20221774-min.webp"),
  w46: require("./assets/os 22/14/OS BY OS FALL WINTER 22-23 27 AUGUST 20221781-min.webp"),
  w47: require("./assets/os 22/14/OS BY OS FALL WINTER 22-23 27 AUGUST 20221824-min.webp"),
  w48: require("./assets/os 22/14/OS BY OS FALL WINTER 22-23 27 AUGUST 20221854 copy-min.webp"),
  w49: require("./assets/os 22/14/OS BY OS FALL WINTER 22-23 27 AUGUST 20221926-min.webp"),
  w50: require("./assets/os 22/15/OS BY OS FALL WINTER 22-23 27 AUGUST 20221211-min.webp"),
  w51: require("./assets/os 22/15/OS BY OS FALL WINTER 22-23 27 AUGUST 20221237-min.webp"),
  w52: require("./assets/os 22/15/OS BY OS FALL WINTER 22-23 27 AUGUST 20221273-min.webp"),
  w53: require("./assets/os 22/15/OS BY OS FALL WINTER 22-23 27 AUGUST 20221293-min.webp"),
  w54: require("./assets/os 22/16/OS BY OS FALL WINTER 22-23 27 AUGUST 20221329-min.webp"),
  w55: require("./assets/os 22/16/OS BY OS FALL WINTER 22-23 27 AUGUST 20221342-min.webp"),
  w56: require("./assets/os 22/16/OS BY OS FALL WINTER 22-23 27 AUGUST 20221358-min.webp"),
  w57: require("./assets/os 22/16/OS BY OS FALL WINTER 22-23 27 AUGUST 20221362-min.webp"),
};

const os21Evento = {
  1: require("./assets/lux evento 2021/1/IMG_0242-min.JPG"),
  2: require("./assets/lux evento 2021/1/IMG_0243-min.JPG"),
  3: require("./assets/lux evento 2021/1/IMG_0245-min.JPG"),
  4: require("./assets/lux evento 2021/1/IMG_0273-min.JPG"),
  5: require("./assets/lux evento 2021/2/IMG_0227-min.JPG"),
  6: require("./assets/lux evento 2021/2/IMG_0229-min.JPG"),
  7: require("./assets/lux evento 2021/2/IMG_0231-min.JPG"),
  8: require("./assets/lux evento 2021/2/IMG_0233-min.JPG"),
  9: require("./assets/lux evento 2021/3/IMG_0235-min.JPG"),
  10: require("./assets/lux evento 2021/3/IMG_0236-min.JPG"),
  11: require("./assets/lux evento 2021/3/IMG_0238-min.JPG"),
  12: require("./assets/lux evento 2021/3/IMG_0240-min.JPG"),
  13: require("./assets/lux evento 2021/4/IMG_0207-min.JPG"),
  14: require("./assets/lux evento 2021/4/IMG_0208-min.JPG"),
  15: require("./assets/lux evento 2021/4/IMG_0209-min.JPG"),
  16: require("./assets/lux evento 2021/4/IMG_0211-min.JPG"),
  17: require("./assets/lux evento 2021/5/IMG_0247-min.JPG"),
  18: require("./assets/lux evento 2021/5/IMG_0248-min.JPG"),
  19: require("./assets/lux evento 2021/5/IMG_0250-min.JPG"),
  20: require("./assets/lux evento 2021/5/IMG_0252-min.JPG"),
  w1: require("./assets/lux evento 2021/1/IMG_0242-min.webp"),
  w2: require("./assets/lux evento 2021/1/IMG_0243-min.webp"),
  w3: require("./assets/lux evento 2021/1/IMG_0245-min.webp"),
  w4: require("./assets/lux evento 2021/1/IMG_0273-min.webp"),
  w5: require("./assets/lux evento 2021/2/IMG_0227-min.webp"),
  w6: require("./assets/lux evento 2021/2/IMG_0229-min.webp"),
  w7: require("./assets/lux evento 2021/2/IMG_0231-min.webp"),
  w8: require("./assets/lux evento 2021/2/IMG_0233-min.webp"),
  w9: require("./assets/lux evento 2021/3/IMG_0235-min.webp"),
  w10: require("./assets/lux evento 2021/3/IMG_0236-min.webp"),
  w11: require("./assets/lux evento 2021/3/IMG_0238-min.webp"),
  w12: require("./assets/lux evento 2021/3/IMG_0240-min.webp"),
  w13: require("./assets/lux evento 2021/4/IMG_0207-min.webp"),
  w14: require("./assets/lux evento 2021/4/IMG_0208-min.webp"),
  w15: require("./assets/lux evento 2021/4/IMG_0209-min.webp"),
  w16: require("./assets/lux evento 2021/4/IMG_0211-min.webp"),
  w17: require("./assets/lux evento 2021/5/IMG_0247-min.webp"),
  w18: require("./assets/lux evento 2021/5/IMG_0248-min.webp"),
  w19: require("./assets/lux evento 2021/5/IMG_0250-min.webp"),
  w20: require("./assets/lux evento 2021/5/IMG_0252-min.webp"),
};

const leisure21 = {
  1: require("./assets/lux leisure 21/1/IMG_0197-min.webp"),
  2: require("./assets/lux leisure 21/1/IMG_0199-min.webp"),
  3: require("./assets/lux leisure 21/1/IMG_0200-min.webp"),
  4: require("./assets/lux leisure 21/1/IMG_0204-min.webp"),
  5: require("./assets/lux leisure 21/2/IMG_0212-min.webp"),
  6: require("./assets/lux leisure 21/2/IMG_0213-min.webp"),
  7: require("./assets/lux leisure 21/2/IMG_0215-min.webp"),
  8: require("./assets/lux leisure 21/2/IMG_0216-min.webp"),
  9: require("./assets/lux leisure 21/3/img7077-min.webp"),
  10: require("./assets/lux leisure 21/3/img7094-min.webp"),
  11: require("./assets/lux leisure 21/3/img7098-min.webp"),
  12: require("./assets/lux leisure 21/3/img7158-min.webp"),
  13: require("./assets/lux leisure 21/4/IMG_0260-min.webp"),
  14: require("./assets/lux leisure 21/4/IMG_0262-min.webp"),
  15: require("./assets/lux leisure 21/4/IMG_0263-min.webp"),
  16: require("./assets/lux leisure 21/4/IMG_0264-min.webp"),
  17: require("./assets/lux leisure 21/5/IMG_0221-min.webp"),
  18: require("./assets/lux leisure 21/5/IMG_0222-min.webp"),
  19: require("./assets/lux leisure 21/5/IMG_0223-min.webp"),
  20: require("./assets/lux leisure 21/5/IMG_0224-min.webp"),
  21: require("./assets/lux leisure 21/6/IMG_0266-min.webp"),
  22: require("./assets/lux leisure 21/6/IMG_0268-min.webp"),
  23: require("./assets/lux leisure 21/6/IMG_0271-min.webp"),
  24: require("./assets/lux leisure 21/6/IMG_0272-min.webp"),
};

const os20 = {
  1: require("./assets/os20/1/27-10-2020--12581-min.webp"),
  2: require("./assets/os20/1/27-10-2020--12585-min.webp"),
  3: require("./assets/os20/1/27-10-2020--12595-min.webp"),
  4: require("./assets/os20/2/27-10-2020--12619-min.webp"),
  5: require("./assets/os20/2/27-10-2020--12637-min.webp"),
  6: require("./assets/os20/2/27-10-2020--12653-min.webp"),
  7: require("./assets/os20/3/27-10-2020--12686-min.webp"),
  8: require("./assets/os20/3/27-10-2020--12703-min.webp"),
  9: require("./assets/os20/3/27-10-2020--12729-min.webp"),
  10: require("./assets/os20/4/27-10-2020--12735-min.webp"),
  11: require("./assets/os20/4/27-10-2020--12760(1)-min.webp"),
  12: require("./assets/os20/4/27-10-2020--12786-min.webp"),
  13: require("./assets/os20/5/27-10-2020--12797-min.webp"),
  14: require("./assets/os20/5/27-10-2020--12798-min.webp"),
  15: require("./assets/os20/5/27-10-2020--12817-min.webp"),
  16: require("./assets/os20/6/27-10-2020--12900-min.webp"),
  17: require("./assets/os20/6/27-10-2020--12918-min.webp"),
  18: require("./assets/os20/6/Copy of 27-10-2020--12909-min.webp"),
  19: require("./assets/os20/7/img12528-min.webp"),
  20: require("./assets/os20/7/img12545-min.webp"),
  21: require("./assets/os20/7/img12560-min.webp"),
  22: require("./assets/os20/8/27-10-2020--12964.webp"),
  23: require("./assets/os20/8/27-10-2020--12967.webp"),
  24: require("./assets/os20/8/27-10-2020--12981.webp"),
  25: require("./assets/os20/9/27-10-2020--12996.webp"),
  26: require("./assets/os20/9/27-10-2020--13029.webp"),
  27: require("./assets/os20/9/27-10-2020--13036.webp"),
  28: require("./assets/os20/10/27-10-2020--13088.webp"),
  29: require("./assets/os20/10/27-10-2020--13140.webp"),
  30: require("./assets/os20/10/27-10-2020--13142.webp"),
  31: require("./assets/os20/11/27-10-2020--13223.webp"),
  32: require("./assets/os20/11/27-10-2020--13234.webp"),
  33: require("./assets/os20/11/27-10-2020--13240.webp"),
};

const celebrities = {
  aparshakti: require("./assets/Celebrities/aparshakti  khurana in osbyos @iifa2022 (2).webp"),
  ayushman1: require("./assets/Celebrities/ayushman khurrana in osbyos @flimfare awards 2021.webp"),
  ayushman1: require("./assets/Celebrities/Ayushmann Khurrana in osbyos @abpnewstv.webp"),
  ayushman1: require("./assets/Celebrities/Ayushmann Khurrana in osbyos @filmfare cover.webp"),
  dilquer: require("./assets/Celebrities/dilquer salmaan in osbyos @bbc top gear mag india awards.webp"),
  srk1: require("./assets/Celebrities/srk  in osbyos @Crystal Award at World-Economic Forum in Davos (2).webp"),
  srk2: require("./assets/Celebrities/srk in osbyos @burjeel ad.webp"),
  srk3: require("./assets/Celebrities/srk in osbyos @Vogue Women Of The Year Awards.1.webp"),
  armaan: require("./assets/Celebrities/Armaan Malik in osbyos @how many song GQ cover India.webp"),
  srk5: require("./assets/Celebrities/srk in osbyos honoured latrobeuni doctrate in melbourne.webp"),
  vicky: require("./assets/Celebrities/vicky kaushal in osbyos @MansWorld July 2021 Cover story.webp"),
  virat: require("./assets/Celebrities/virat in osbyos for his wedding.webp"),
  virat1: require("./assets/Celebrities/virat kohli in osbyos @charity ball london.webp"),
  virat2: require("./assets/Celebrities/virat kohli in osbyos @zaheer khan wedding (2).webp"),
};

const press = {
  1: require("./assets/Press/ali fazal in osbyos @manworld lexus campaign.webp"),
  2: require("./assets/Press/aparshakti  khurana in osbyos @iifa2022.webp"),
  3: require("./assets/Press/Armaan Malik in osbyos @how many song GQ cover India (1).webp"),
  4: require("./assets/Press/Ayushmann-Khurrana in osbyos with Riteish Deshmukh Ayushmann Khurrana and Rajkummar-Rao shake a leg on stage at Filmfare Awards .jpg (1).webp"),
  5: require("./assets/Press/ms dhoni in osbyos @oreo india.webp"),
  6: require("./assets/Press/srk  in osbyos @Crystal Award at World-Economic Forum in Davos.webp"),
  7: require("./assets/Press/srk in osbyos @burjeel ad (2).webp"),
  8: require("./assets/Press/usamaah siddique in osbyos @cosmopolitan magazine.webp"),
  9: require("./assets/Press/Virat Kohli in osbbyos @Indian Sports Honours awards show (1).webp"),
  10: require("./assets/Press/srk in osbyos @ iffm press conference melbourne.webp"),
};

export {
  HomeImages,
  mills,
  os22,
  celebrities,
  os21Evento,
  leisure21,
  os20,
  press,
};
