import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { press } from "../Images";

export default function Press() {
  return (
    <div className="press">
      <ImageList cols={2}>
        {itemData.map((item) => (
          <ImageListItem
            sx={{
              width: "40vw",
              height: "40vh",
            }}
            key={item.img}
          >
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            {/* <h3 style={{ padding: "25px" }}>{item.title}</h3> */}
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

const itemData = [
  {
    img: press[7],
    title:
      "SHAHRUKH KHAN WEARING OSBYOS AT A CEREMONY BY BURJEEL HOLDINGS AS HE WAS APPOINTED THEIR BRAND AMBASSADOR",
  },
  {
    img: press[9],
    title:
      "VIRAT KOHLI WEARING OSBYOS FOR THE INDIAN SPORTS HONOURS AWARDS SHOW",
  },
  {
    img: press[6],
    title:
      "SHAHRUKH KHAN WEARING OSBYOS HONOURED WITH CRYSTAL AWARD AT WORLDECONOMIC FORUM IN DAVOS, SWITZERLAND",
  },
  {
    img: press[5],
    title:
      "MS DHONI WEARING OSBYOS GOES LIVE FOR THE PRESS CONFERENCE OF OREO INDIA",
  },
  {
    img: press[10],
    title:
      "ShahRukh Khan WEARING OSBYOS FOR the Indian Film Festival of Melbourne IIFM",
  },

  {
    img: press[1],
    title: "ALI FAZAL WEARING OSBYOS FOR THE MANSWORLD COVER SHOOT",
  },
  {
    img: press[2],
    title:
      "APARSHAKTI KHURRANA IN OSBYOS BESPOKE LIQUID VELVET JACKET, EVENING SHIRT WITH BIB, EVENING WOOL CANVAS TROUSER, HANDMADE BOWTIE AND CUMMERBAND FOR IIFA AWARDS",
  },
  {
    img: press[3],
    title: "ARMAN MALIK WEARING OSBYOS FOR THE GQ DIGITAL COVER SHOOT",
  },
  {
    img: press[4],
    title: "Ayushmann Khurrana WEARING OSBYOS FOR FILMFARE AWARDS",
  },

  {
    img: press[8],
    title:
      "USAAMAH SIDDIQUE WEARING OSBYOS FEATURED IN THE COSMOPOLITAN MAGAZINE",
  },
];
