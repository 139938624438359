import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../assets/OSBYOSLogo.jpg";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#010203",
          width: "100%",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          width: "100%",
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          background: "#010203",
          color: "#FEFEFE",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Melbourne",
          fontSize: "1rem",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Melbourne",
        },
      },
    },
  },
});

const drawerWidth = "50%";
const navItems = [
  "COLLECTION",
  "SHOP",
  "TRUNK SHOW",
  "PRESS",
  "CELEBRITIES",
  "CONTACT",
];

export default function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const [collection, setCollection] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navHandler = (value) => {
    if (value == "CONTACT") {
      setMobileOpen(!mobileOpen);
      return navigate("/Contact")[navigate];
    } else if (value == "CELEBRITIES") {
      setMobileOpen(!mobileOpen);
      return navigate("/Celebrities")[navigate];
    } else if (value == "SHOP") {
      setMobileOpen(!mobileOpen);
      return navigate("/Shop")[navigate];
    } else if (value == "TRUNK SHOW") {
      setMobileOpen(!mobileOpen);
      return navigate("/TrunkShow")[navigate];
    } else if (value == "PRESS") {
      setMobileOpen(!mobileOpen);
      return navigate("/Press")[navigate];
    }
  };
  const mouseEnter = (value) => {
    if (value == "COLLECTION") {
      setCollection(true);
    }
  };

  const mouseLeave = (value) => {
    if (value == "COLLECTION") {
      setCollection(false);
    }
  };

  const navCollection = () => {
    setMobileOpen(!mobileOpen);
    return navigate("/Collection", { state: { type: "os22", row: 3 } })[
      navigate
    ];
  };
  const navCollectionEvent = () => {
    setMobileOpen(!mobileOpen);
    return navigate("/Collection", { state: { type: "evento21", row: 3 } })[
      navigate
    ];
  };

  const leisure = () => {
    setMobileOpen(!mobileOpen);
    return navigate("/Collection", { state: { type: "leisure21", row: 3 } })[
      navigate
    ];
  };
  const os20 = () => {
    setMobileOpen(!mobileOpen);
    return navigate("/Collection", { state: { type: "os20", row: 3 } })[
      navigate
    ];
  };

  const logonav = () => {
    return navigate("/")[navigate];
  };

  const drawer = (
    <Box
      sx={{
        textAlign: "center",
        backgroundColor: "#010203",
        color: "#fff",
        fontSize: "2rem",
        fontFamily: "Melbourne",
      }}
    >
      <ThemeProvider theme={theme}>
        <Typography variant="h4" sx={{ my: 2 }}>
          OSBYOS
        </Typography>
        <Divider />
        <List>
          {navItems.map((item) =>
            item == "COLLECTION" ? (
              <>
                <ListItem key={item}>
                  <ListItemButton
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    onClick={navCollection}
                  >
                    <ListItemText primary="OS 22" />
                  </ListItemButton>
                </ListItem>
                <ListItem key={item}>
                  <ListItemButton
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    onClick={navCollectionEvent}
                  >
                    <ListItemText primary=" OS21 LUX EVENTO" />
                  </ListItemButton>
                </ListItem>
                <ListItem key={item}>
                  <ListItemButton
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    onClick={leisure}
                  >
                    <ListItemText primary="OS21 LUX LEISURE" />
                  </ListItemButton>
                </ListItem>
                <ListItem key={item}>
                  <ListItemButton
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    onClick={os20}
                  >
                    <ListItemText primary="OS20" />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <ListItem key={item}>
                <ListItemButton
                  onClick={() => {
                    navHandler(item);
                  }}
                  sx={{ textAlign: "center" }}
                >
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </ThemeProvider>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", paddingLeft: "0px", paddingRight: "0px" }}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <AppBar component="nav" position="sticky">
            <div className="FirstAppBar">
              <img
                src={logo}
                alt="OSBYOS LOGO"
                width="100vw"
                height="auto"
                onClick={logonav}
              ></img>
            </div>

            <Toolbar sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  position: "absolute",
                  left: "10%",
                  top: "20%",
                  mr: 2,
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                }}
              >
                {navItems.map((item) => (
                  <Button
                    onClick={() => {
                      navHandler(item);
                    }}
                    onMouseEnter={() => {
                      mouseEnter(item);
                    }}
                    onMouseLeave={() => {
                      mouseLeave(item);
                    }}
                    key={item}
                    sx={{ color: "#fff", marginLeft: "3rem" }}
                  >
                    {item}
                  </Button>
                ))}
              </Box>
            </Toolbar>
          </AppBar>
        </ThemeProvider>

        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#010203",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Toolbar />
      </Box>
      <ThemeProvider theme={theme}>
        <Collapse
          onMouseOver={() => {
            setCollection(true);
          }}
          onMouseLeave={(e) => {
            setCollection(false);
          }}
          in={collection}
        >
          <div className="SpringSummer">
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={navCollection}>
                  <ListItemText primary="OS 22" />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
          <div className="Archive">
            <List>
              <ListItem disablePadding>
                <h4>Archive</h4>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={navCollectionEvent}>
                  <ListItemText primary="OS21 LUX EVENTO" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={leisure}>
                  <ListItemText primary="OS21 LUX LEISURE" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={os20}>
                  <ListItemText primary="OS20" />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </Collapse>
      </ThemeProvider>
    </React.Fragment>
  );
}
