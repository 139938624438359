import React from "react";
import under from "../assets/under.webp";

function Shop() {
  return (
    <React.Fragment>
      <div className="Shop">
        <img src={under} />
      </div>
    </React.Fragment>
  );
}

export default Shop;
